@import '../../variables';

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $umami-black;
  color: $umami-gold;
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .img {
      img {
        max-height: 100px;
        width: auto;
        margin-bottom: 10px;
      }
    }
    .text {
      font-size: 1.5rem;
      font-family: 'Poppins', sans-serif;
    }
    .subtext {
      margin-top: 20px;
      font-size: 2rem;
      opacity: 0.5;
    }
  }
}
