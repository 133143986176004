@import '../../variables';

.top {
  background: $umami-gold;
  padding: 20px;

  .title {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0 0 12px 0;
  }

  .description {
    font-size: 1rem;
  }
}
.content {
  padding: 20px;
}
