@import '../../variables';

.container {
  padding: 20px;
}

.label {
  margin-bottom: 3px;
  margin-top: 10px;

  &.error {
    color: $umami-red;
  }

  small {
    opacity: 0.5;
  }
}

.errorText {
  color: $umami-red;
  font-size: 0.8em;
}
