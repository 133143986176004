@import '../../variables';

$left-width: 280px;

.container {
  padding: 20px;

  .row {
    &:after {
      content: '';
      display: table;
      clear: both;
    }

    .left {
      width: $left-width;
      float: left;
    }

    .right {
      width: calc(100% - #{$left-width});
      float: left;
      padding: 0 20px 20px 20px;
    }
  }
  .label {
    margin-bottom: 3px;
    margin-top: 10px;
    small {
      opacity: 0.5;
    }
  }
  hr {
    border-top: 2px solid $umami-gold;
    margin: 20px 0;
  }
  .toggleBox {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    .toggleText {
      flex-grow: 1;
      padding: 3px 8px;
      small {
        opacity: 0.5;
      }
    }
    .switchBox {
    }
  }
  pre {
    background: #eae4dd;
    padding: 10px;
    border-radius: 5px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
}

.colorBox {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: inline-block;
  cursor: pointer;
}
