@import '../variables';

.notification {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;

  .title {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0 0 12px 0;
    margin-bottom: 10px;
  }

  .description {
    font-size: 1rem;
  }

  .footer {
    margin: 20px 0;

    .avatar {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      background: #000;
      text-align: center;
      padding: 4px;
      transform: translateY(4px);

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .name {
    font-size: 1rem;
    color: $umami-gold;
  }

  .date {
    margin-left: 10px;
    font-size: 0.8rem;
    color: #999;
  }
}

.delete {
  margin-left: 10px;
  display: inline-block;
  font-size: 12px;
  color: #9b1007;
  cursor: pointer;
}
