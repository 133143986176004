@import '../../variables';

.container {
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  border-radius: 10px;
  margin: 20px 0px;
  padding: 18px 8px;

  .details {
    margin: 20px;

    .title {
      font-size: 16px;
      font-weight: 600;
    }

    .description {
      font-size: 15px;
      font-weight: 400;
    }

    .iconWithText {
      display: flex;
      align-items: center;
      margin: 10px 0;
      .icon {
        margin-right: 10px;
        img {
          height: 22px;
        }
      }
      .text {
        flex-grow: 1;
      }
    }

    .video {
      margin: 10px 0;
      padding: 0;
    }
    
  }

  .foodPairings {
    margin: 20px;
  
    .title {
      font-size: 16px;
      font-weight: 600;
    }
  
    .iconWithText {
      display: flex;
      align-items: center;
      margin: 10px 0;
  
      .icon {
        margin-right: 10px;
  
        img {
          height: 22px;
        }
      }
  
      .text {
        flex-grow: 1;
      }
    }
  }

  .wineRegion {
    margin: 20px;
  
    .title {
      font-size: 16px;
      font-weight: 600;
    }
  
    .iconWithText {
      display: flex;
      align-items: center;
      margin: 10px 0;
  
      .icon {
        margin-right: 10px;
  
        img {
          height: 22px;
        }
      }
  
      .text {
        flex-grow: 1;
      }
    }
  }
}

$thumb-box-size: 256px;
.thumbBox {
  float: right;
  height:$thumb-box-size;
  width:$thumb-box-size;
  text-align: center;
  margin: 0 0 24px 24px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.poweredBy {
  position: absolute;
  top: 18px;
  left: 16px;
  display: flex;
  padding: 0 10px;

  .iconBox {
    height: 24px;
  }

  .textBox {
    flex-grow: 1;
    padding-left: 10px;

    .top {
      font-size: 8px;
      font-family: 'Poppins', sans-serif;
      line-height: 8px;
    }

    .bottom {
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
    }
  }
}

// clear fix
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.loadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px;
  .loadingIcon {
    margin-right: 10px;
  }
  .loadingText {
    font-size: 16px;
  }
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}
