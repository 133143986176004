@import '../../variables';

$borderRadius: 20px;

.login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $umami-black;
  color: $umami-gold;

  .loginContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 300px;
    width: 80%;
    box-shadow: 0 0 200px 0 rgba(45, 44, 23, 0.5);
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;

    .loginHeader {
      font-size: 28px;
      font-family: 'Poppins', sans-serif;
      height: 70px;
      line-height: 70px;

      img {
        height: 40px;
        width: auto;
        margin-right: 10px;
        transform: translateY(9px);
      }
    }

    .loginBody {
      background: $umami-gold;
      padding: 30px 20px 10px;
      border-bottom-left-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;

      .error {
        color: #fff;
        font-size: 12px;
        margin-bottom: 20px;
        background: transparentize($umami-red, 0.4);
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid $umami-red;
      }

      .loginForm {
        margin-top: 20px;

        .loginFormInput {
          margin-bottom: 10px;
        }
      }
    }
  }
}
