@import './variables';

html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-layout {
  min-height: 100%;
  background: #fff9f4;
}

th.ant-table-cell {
  background: $umami-gold !important;
}

.ant-layout-header {
  padding-inline: 16px !important;
}

.side-menu {
  .ant-menu {
    background-color: #fff9f4 !important;
    border-inline-end: 0px !important;
  }

  .ant-menu-sub {
    background-color: #f2ebe2 !important;

    .ant-menu-item {
      color: $umami-black !important;

      &:hover {
        background-color: $umami-gold !important;
      }
    }
  }

  .ant-menu-submenu-title {
    background-color: #fff9f4 !important;
    color: $umami-black !important;

    &:hover {
      color: $umami-black !important;
      background-color: $umami-gold !important;
    }
  }

  .ant-menu-item-selected {
    background-color: $umami-gold !important;
    color: $umami-black !important;

    &:hover {
      background-color: $umami-gold !important;
    }
  }
}

.spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.text-danger {
  color: $umami-red !important;
}
