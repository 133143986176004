@import './variables';

.logo {
  float: left;
  font-family: 'Poppins', sans-serif;
  color: $umami-gold;
  height: 100%;
  position: relative;
  padding-left: 40px;
  font-size: 20px;
  font-weight: 200;

  img {
    height: 32px;
    width: auto;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .Sider {
    background: #000000;
  }
}

.headerRight {
  float: right;
  height: 100%;
  position: relative;
  font-size: 16px;
  font-weight: 200;

  .headerRightItem {
    display: inline-block;
    height: 100%;
    position: relative;
    padding: 0 20px;
    line-height: 64px;
    color: $umami-gold;
    cursor: pointer;

    .icon {
      font-size: 18px;
      margin-right: 4px;
      display: inline-block;
      transform: translateY(3px);
    }

    &:hover {
      color: $umami-gold;
    }
  }
}

.dropdownIcon {
  font-size: 18px;
  margin-right: 8px;
  display: inline-block;
  transform: translateY(4px);
}
