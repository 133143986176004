@import '../../variables';

$left-width: 280px;
.container {
  padding: 20px;

  .left {
    width: $left-width;
    float: left;
  }

  .right {
    width: calc(100% - #{$left-width});
    float: left;
  }

  .clearfix {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .titleTop {
    display: flex;

    .titleTopLeft {
    }

    .titleTopCenter {
      flex-grow: 1;
    }

    .titleTopRight {
    }

    // clearfix after
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

.item {
  margin-bottom: 20px;
  display: flex;

  .imgBox {
    width: 75px;
    height: 75px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .mainBox {
    flex-grow: 1;
    padding: 2px 10px;

    small {
      opacity: 0.5;
    }

    .inputBox {
      margin: 5px 0;
    }

    .title {
      font-size: 0.7rem;
      opacity: 0.5;
      margin-left: 5px;
    }
  }

  .toggleBox {
    text-align: center;

    .toggleText {
      font-size: 10px;
      color: #8f8880;
      text-align: center;
      font-weight: 600;
      margin-top: 18px;
    }
  }

  .buttonBox {
    padding: 20px 0 0 10px;
  }
}
